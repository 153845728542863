<template>
  <div>
    <div class="row">
      <div class="col-6">
        <div class="card">
          <div class="card-body">
            <div class="d-flex flex-column align-items-center text-center">
              <div class="mt-3">
                <h4 v-if="!edit">{{service.name}}</h4>
                <input v-else class="form-control" style="width: 75%;" type="text" v-model="service.name">
              </div>
            </div>
            <hr class="my-4">
            <ul class="list-group list-group-flush mb-2">
              <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                <h6 class="mb-0">All Sites?</h6>
                <input class="form-check-input" v-model="service.all_sites" type="checkbox" value="" id="flexCheckChecked" :disabled="!edit">
              </li>
              <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                <h6 class="mb-0">API Key</h6>
                <div class="input-group input-group-sm" style="max-width: 350px;">
                  <input class="form-control form-control-sm" v-model="service.api_key" type="password" disabled>
                  <button class="btn btn-sm btn-light" type="button" id="button-addon2" @click="copyApiKey">Copy</button>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ClientMonitoringService",
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    client_id: {
      type: [String, Number],
      required: true
    }
  },
  data(){
    return {
      service: {

      },
      edit: false
    }
  },
  mounted(){
    this.loadService();
  },
  methods: {
    loadService(){
      axios.get(`${process.env.VUE_APP_API_URL}/v1/client/${this.client_id}/monitoring/${this.id}`)
      .then(response => {
        this.service = response.data.service;
        this.service.all_sites = this.service.all_sites == 1;
      })
      .catch(error => {
        this.$error("Failed to load service", error);
      })
    },
    copyApiKey(){
      navigator.clipboard.writeText(this.service.api_key).then(() => {
        this.$success("Copied Key!");
      }, (err) => {
        this.$error("Failed to copy key!", err);
      });
    }
  }
}
</script>

<style scoped>

</style>